import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { catchError, finalize, map, Observable, of } from 'rxjs';
import { OfferService } from '../services/offer.service';
import { NotificationService } from '../services/notification.service';
import { CampaignStateService } from '../services/campaign-state.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignGuard implements CanActivate {
  constructor(
    private offerService: OfferService,
    private campaignState: CampaignStateService,
    private notificationService: NotificationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    const campaignSlug = route.queryParams['campaign'];
    this.campaignState.reset();

    if (!campaignSlug) {
      this.campaignState.setLoading(false);
      return of(true);
    }

    this.campaignState.setCampaignSlug(campaignSlug);

    return this.offerService.getOfferFromCampaignSlug(campaignSlug).pipe(
      map((meta) => {
        this.campaignState.setCampaignMeta(meta);
        this.campaignState.setColor(meta);
        return true;
      }),
      catchError(() => {
        this.notificationService.showError('Failed to load campaign information');
        return of(true); // Still allow navigation, just without campaign data
      }),
      finalize(() => {
        this.campaignState.setLoading(false);
      })
    );
  }
}