import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ForgotPasswordComponent } from 'src/app/modules/auth/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/modules/auth/login/login.component';
import { NewPasswordComponent } from 'src/app/modules/auth/new-password/new-password.component';
import { SignUpComponent } from 'src/app/modules/auth/sign-up/sign-up.component';
import { VerifyEmailComponent } from 'src/app/modules/auth/verify-email/verify-email.component';
import { CampaignGuard } from 'src/app/shared/guards/campaign.guard';

const routes: Routes = [
  {
    path: 'auth/login',
    component: LoginComponent,
    canActivate: [CampaignGuard]
  },
  {
    path: 'auth/sign-up',
    component: SignUpComponent,
    canActivate: [CampaignGuard]
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'auth/reset-password',
    component: NewPasswordComponent,
  },
  {
    path: 'auth/verify-email',
    component: VerifyEmailComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
