import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CampaignMeta } from '../models/Auth';
import { ColorService } from './color.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignStateService {
  private loadingSubject = new BehaviorSubject<boolean>(true);
  private campaignMetaSubject = new BehaviorSubject<CampaignMeta>({});
  private campaignSlugSubject = new BehaviorSubject<string>('');

  loading$ = this.loadingSubject.asObservable();
  campaignMeta$ = this.campaignMetaSubject.asObservable();
  campaignSlug$ = this.campaignSlugSubject.asObservable();

  constructor(
    private colorService: ColorService
  ) { }

  setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  setCampaignMeta(meta: CampaignMeta) {
    this.campaignMetaSubject.next(meta);
  }

  setCampaignSlug(slug: string) {
    this.campaignSlugSubject.next(slug);
  }

  setColor(meta: CampaignMeta) {
    document.documentElement.style.setProperty('--campaign-color', meta.color || '#000000');
    document.documentElement.style.setProperty('--campaign-contrast-color', this.colorService.getContrastColor(meta.color));
    document.documentElement.style.setProperty('--campaign-background', `url(${meta.background || ''})`);
  }

  reset() {
    this.loadingSubject.next(true);
    this.campaignMetaSubject.next({});
    this.campaignSlugSubject.next('');
  }
}