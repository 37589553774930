import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ColorService {

    /**
     * Get the contrast color based on the hex color
     * @param hexcolor 
     * @returns 
     */
    getContrastColor(hexcolor?: string): string {
        if (!hexcolor) return '#ffffff';

        // Remove the # if present
        const hex = hexcolor.replace('#', '');

        // Convert to RGB
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        // Calculate luminance
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

        // Return black or white based on luminance
        return luminance > 0.5 ? '#000000' : '#ffffff';
    }
}
