<ng-container *ngIf="(loading$ | async) === false">
  <app-header-v2 [logoUrl]="(campaignMeta$ | async)?.logo?.['96p']"
    [logoAlt]="(campaignMeta$ | async)?.name ? (campaignMeta$ | async)?.name + ' Logo' : 'Company Logo'">
    <span class="text-black mr-3">
      Need an account?
    </span>
    <button type="button" [routerLink]="['/auth/sign-up']" queryParamsHandling="preserve" class="btn campaign-btn"
      [ngClass]="{'campaign-btn-color': (campaignMeta$ | async)?.color, 'btn-danger': !(campaignMeta$ | async)?.color }">
      Create Account
    </button>
  </app-header-v2>

  <div class="row bg-radial align-items-center campaign-background"
    [style.backgroundImage]="(campaignMeta$ | async)?.background ? 'url(' + (campaignMeta$ | async)?.background + ')' : null">
    <div class="col-md-8 offset-md-2" [ngClass]="{'bg-white box-shadow': (campaignMeta$ | async)?.color}">
      <div class="row">
        <div class="col-md-6" [ngClass]="{'vertical-separator-right': (campaignMeta$ | async)?.color,
          'campaign-background-overlay': (campaignMeta$ | async)?.background
        }">
          <app-features [color]="(campaignMeta$ | async)?.color ? 'white' : null"
            [style.color]="(campaignMeta$ | async)?.color ? 'white' : null"
            [heading]="(campaignMeta$ | async)?.name ? 'Login to the ' + (campaignMeta$ | async)?.name + ' affiliate program' : 'Experience seamless affiliate marketing management'">
            <span id="feature-1" *ngIf="!(campaignMeta$ | async)?.name">Enhance your affiliate marketing with
              personalized support and advanced tools.</span>
            <span id="feature-1" *ngIf="(campaignMeta$ | async)?.name">Enhance your affiliate marketing with advanced
              tools.</span>

            <span id="feature-2" *ngIf="!(campaignMeta$ | async)?.name">Connect with dedicated account managers for
              tailored growth solutions.</span>
            <span id="feature-2" *ngIf="(campaignMeta$ | async)?.name">Connect with {{(campaignMeta$ | async)?.name}}
              for tailored growth solutions.</span>

            <span id="feature-3" *ngIf="!(campaignMeta$ | async)?.name">Experience a network where your needs are our
              priority, trusted by industry leaders.</span>
            <span id="feature-3" *ngIf="(campaignMeta$ | async)?.name">Benefit from a direct partnership with
              {{(campaignMeta$ | async)?.account_name}}.</span>
          </app-features>
        </div>

        <div class="col-md-6">
          <div class="login-box ml-auto" [ngClass]="{'box-shadow-none': (campaignMeta$ | async)?.color}">
            <div class="login-box-header pt-4">
              <h2 class="text-black pt-2 text-center">Welcome Back!</h2>
            </div>
            <div class="login-box-body">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group mb-4">
                  <label>Email</label>
                  <input [formControl]="email" type="text" class="form-control" placeholder="Email" />
                </div>
                <div class="form-group mb-4">
                  <label>Password</label>
                  <input [formControl]="password" type="password" class="form-control" placeholder="Password" />
                </div>
                <div class="w-100">
                  <button type="submit" [disabled]="loginForm.invalid"
                    class="btn w-100 d-flex align-items-center justify-content-center"
                    [ngClass]="{'campaign-btn-color': (campaignMeta$ | async)?.color, 'btn-primary': !(campaignMeta$ | async)?.color }">
                    <img src="/assets/icons/circle-check.svg" alt="loader" class="mr-2" />
                    Log In
                  </button>
                </div>
                <div class="password-reset-link text-center mt-4">
                  <a [routerLink]="['/auth', 'forgot-password']" class="text-center">Forgot
                    Password?</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <app-footer-v2></app-footer-v2>
</ng-container>
