import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { termsAndConditionsContent, termsAndConditionsExternalLink } from 'src/app/config/terms';
import { PasswordValidator } from 'src/app/shared/constants/auth';
import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CampaignStateService } from 'src/app/shared/services/campaign-state.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TitleService } from 'src/app/shared/services/title-service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})

export class SignUpComponent implements OnInit {
  termsAndConditionsContent = termsAndConditionsContent;
  termsAndConditionsExternalLink = termsAndConditionsExternalLink;

  firstName = new UntypedFormControl('', [Validators.required]);
  lastName = new UntypedFormControl('', [Validators.required]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(5)]);
  agreeToTermAndConditions = new UntypedFormControl(false, [Validators.requiredTrue]);
  signUpForm: UntypedFormGroup;

  submitted = false;
  loading$ = this.campaignState.loading$;
  campaignMeta$ = this.campaignState.campaignMeta$;
  campaignSlug$ = this.campaignState.campaignSlug$;

  constructor(
    private authService: AuthService,
    public router: Router,
    private notificationService: NotificationService,
    private formBuilder: UntypedFormBuilder,
    private coreService: CoreService,
    private titleService: TitleService,
    private campaignState: CampaignStateService
  ) { }

  regexValidator(regex: RegExp, key: string): ValidatorFn {
    return (control: AbstractControl): Record<string, any> | null => {
      const isInvalid = !regex.test(control.value);
      const res = {};
      if (isInvalid) {
        res[key] = { value: control.value };
        return res;
      }
      return null;
    };
  }

  ngOnInit(): void {
    this.titleService.setTitle('Publisher Signup');
    this.signUpForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', PasswordValidator.rules],
      confirmPassword: ['', Validators.required],
      agreeToTermAndConditions: this.agreeToTermAndConditions,
    }, {
      validator: MustMatch('password', 'confirmPassword'),
    });
  }

  get signUpFormControls() {
    return this.signUpForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    }
    const data = {
      first_name: this.signUpForm.value.firstName,
      last_name: this.signUpForm.value.lastName,
      email: this.signUpForm.value.email,
      password1: this.signUpForm.value.password,
      password2: this.signUpForm.value.confirmPassword,
    };
    this.authService.signUp(data).subscribe(
      () => {
        this.coreService.showMessageAndRedirect(
          'Success',
          'Sign up Successful, Check your Email to Verify your Account.',
          'success',
          '/auth/login',
        );
      },
      (err: any) => {
        if (err.error.email) {
          this.notificationService.showError(err.error.email[0]);
          return;
        }
        this.notificationService.showError('Problem Signing Up, Try Again');
      },
    );
  }
}
